<template>
  <v-expansion-panels flat class="pa-1 home-category" v-model="isOpened">
    <v-expansion-panel>
      <v-expansion-panel-header class="category">
        <v-checkbox
          class="ml-n5"
          v-model="isChecked"
          :indeterminate="isIndeterminate"
          @click.native.capture="changeStatus($event)"
        />
        {{ name }} <v-spacer />
        <span class="sel-count"
          >{{ selectedChannels.length }} / {{ channels.length }}</span
        >
      </v-expansion-panel-header>
      <v-list class="channels-dropdown" v-if="hasPremium">
        <v-list-item-group class="ml-4">
          <v-list-item
            :class="`icon_${channel.payment_rate}`"
            v-for="channel in showChannelsPrem"
            :key="channel.name"
            @click="selectChannel(null, channel.name)"
          >
            <template v-slot>
              <v-list-item-action style="position: relative;">
                <div style="position: absolute; top: 0; left: 0; height: 22px; width: 22px; background-color: rgba(251, 147, 0, .6);  border-radius: 2px; outline: 2px solid rgba(251, 147, 0, .6);"></div>
                <div style="position: absolute; top: 3px; left: 3px; height: 16px; width: 16px; background-color: white;"></div>
                <v-checkbox  style="position: absolute; top: -1px; left: -1px;"
                  @click="selectChannel($event, channel.name)"
                  :input-value="selectedChannels.includes(channel.name)"
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content v-if="channel.payment_rate=='premium'">
                <v-list-item-title class="font-weight-bold">{{ channel.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content v-if="channel.payment_rate=='super_premium'">
                <v-list-item-title class="font-weight-bold">{{ channel.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
        <a
          class="ml-7 openAllChannels"
          v-if="isOpened === undefined && hasPremium"
          @click="isOpened = 0"
          >раскрыть полностью</a
        >
      </v-list>
      <v-expansion-panel-content>
        <v-list class="channels-dropdown mt-n3">
          <v-list-item-group class="ml-4">
            <v-list-item
              :class="`icon_${channel.payment_rate}`"
              v-for="channel in showChannelsEco"
              :key="channel.name"
              @click="selectChannel(null, channel.name)"
            >
              <template v-slot>
                <v-list-item-action>
                  <v-checkbox
                    @click="selectChannel($event, channel.name)"
                    :input-value="selectedChannels.includes(channel.name)"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content v-if="channel.payment_rate=='free'">
                  <v-list-item-title>{{ channel.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content v-if="channel.payment_rate=='economy'">
                  <v-list-item-title class="font-weight-bold">{{ channel.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "Category",
  props: {
    name: String,
    channels: Array,
  },
  mounted() {
    const order = {
      super_premium: 1,
      premium: 2, 
      economy: 3,
      free: 4,
    }
    let channels = [...this.channels]
    channels.sort((item1, item2) => (order[item1.payment_rate] - order[item2.payment_rate]) || (item2.payment_pos - item1.payment_pos) );
    this.sortedChannels = channels;
    //this.allOpened = false;
    this.isOpened = undefined;
    this.showChannelsPrem = [...this.sortedChannels].filter( item => item.payment_rate=="super_premium" || item.payment_rate=="premium")
    this.showChannelsEco = [...this.sortedChannels].filter( item => item.payment_rate=="free" || item.payment_rate=="economy")
  },
  computed: {
    hasPremium() {
      const channels = this.channels.filter(item => item.payment_rate=="super_premium" || item.payment_rate=="premium");
      return channels.length > 0;
    },
    isIndeterminate() {
      try {
        if (
          this.selectedChannels.length > 0 &&
          this.selectedChannels.length < this.channels.length
        ) {
          return true;
        }
      } catch (e) {
        console.log(e);
        return false;
      }

      return false;
    },
  },
  methods: {
    changeStatus(event) {
      if (this.selectedChannels.length < this.channels.length) {
        this.isChecked = true;
        this.selectedChannels = [...this.channels.map( c=>c.name)];
      } else {
        this.selectedChannels = [];
        this.isChecked = false;
      }

      event.stopPropagation();
    },
    selectChannel(event, channel) {
      if (event) {
        event.stopPropagation();
      }
      const index = this.selectedChannels.indexOf(channel);

      if (index > -1) {
        this.selectedChannels.splice(index, 1);
        return;
      }
      this.selectedChannels.push(channel);
    },
    setSelectedChannels(channels) {
      this.selectedChannels = channels;
    },
  },
  watch: {
    isOpened(value) {
      console.log(`Opened: ${value}`);
      if (value === 0) {
        if (this.hasPremium) {
        //  this.allOpened = false;
       //   this.showChannels = [...this.sortedChannels].filter( item => item.payment_rate=="super_premium" || item.payment_rate=="premium")
        } else {
       //   this.showChannels = [...this.sortedChannels];
        }
      }

     // if (value===undefined && this.hasPremium) this.isOpened = 0
    },
    allOpened(value) {
      console.log(`All: ${value}`);
      if (value === false) {
        //this.showChannels = [...this.sortedChannels].filter( item => item.payment_rate=="super_premium" || item.payment_rate=="premium")
      } else {
        //this.showChannels = [...this.sortedChannels];
      }
      //console.log(this.sortedChannels, this.showChannels)
    },
    selectedChannels() {
      if (this.selectedChannels.length == this.channels.length) {
        this.isChecked = true;
      } else {
        this.isChecked = false;
      }
      this.$emit("input", this.selectedChannels, this.name);
    },
  },

  data() {
    return {
      isChecked: false,
      isOpened: undefined, // 0 если панель открыта кнопкой
    //  allOpened: false, // true если нажата кнока открыть все
      showChannels: [], // Какие каналы отображены в данный момент (все или прем)
      showChannelsPrem: [], // Какие каналы отображены в данный момент (прем)
      showChannelsEco: [], // Какие каналы отображены в данный момент (не прем)
      sortedChannels: [], // Отсортированные каналы
      selectedChannels: [],
    };
  },
};
</script>

<style lang="scss">
.openAllChannels {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #600DCB!important;
}
.channels-dropdown {
  background-color: #ede7f6!important;
  margin-top: -4px;
  //border-radius: 4px;
  border-bottom-left-radius: 4px!important;
  border-bottom-right-radius: 4px!important;
}
.icon_super_premium {
  background-color: rgba(96, 13, 203, .12);
  border-radius: 4px;
  margin-bottom: 8px;
}
.sel-count {
  text-align: right;
  color: rgba(0, 0, 0, 0.38);
}
.home-category {
  .v-input--selection-controls__ripple {
  }
  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
  .v-list-item__action {
    margin-right: 8px !important;
  }
  .theme--light.v-list-item--active::before {
    opacity: 0 !important;
  }
}
.category {
  background: #ede7f6;
  border-radius: 4px;
  max-height: 32px !important;
  min-height: unset !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  .v-input--checkbox {
    max-width: 30px;
  }
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
}
</style>
