<template>
  <v-app class="bg">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  mounted() {
    // eslint-disable-next-line no-undef
    mh_px(801, {
      ym_uid: this.check_cookie_name("_ym_uid"),
      action: "view",
    });
  },
  methods: {
    check_cookie_name: function (name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
      return "null";
    },
  },
  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
.bg {
  background: url("assets/back.jpg") no-repeat center center fixed !important;
  background-size: cover;
}
</style>
