<template>
  <div class="pt-8 pb-8 pl-2 pl-md-0 pr-2 pr-md-0 container home footerContainer">
    <div class="v-text-body-2">
      Больше информации на сайте «Кабельщик»
    </div>
    <div class="linkChips">
      <div class="linkChip">
        <a href="https://www.cableman.ru/channels/table">Каталог</a>
      </div>
      <div class="linkChip">
        <a href="https://www.cableman.ru/news">Новости</a>
      </div>
      <div class="linkChip">
        <a href="https://www.cableman.ru/channels/news">Новости каналов</a>
      </div>
      <div class="linkChip">
        <a href="https://www.cableman.ru/articles">Статьи</a>
      </div>
      <div class="linkChip">
        <a href="https://www.cableman.ru/tv-profi-1">Спецпроекты</a>
      </div>
      <div class="linkChip">
        <a href="https://www.cableman.ru/reporting">Репортажи</a>
      </div>
      <div class="linkChip">
        <a href="https://www.cableman.ru/events">Календарь</a>
      </div>
      <div class="linkChip">
        <a href="https://www.cableman.ru/blog">Блоги</a>
      </div>
    </div>
    <v-row class="mt-8 d-flex flex-column-reverse flex-md-row pl-0 pr-0">
      <v-col class="col-12 col-md-8 d-flex align-start">
        <v-icon class="mr-2 footerLink">mdi-text-box</v-icon>
        <a href="https://www.cableman.ru/personal_data_policy.pdf" download class="mr-4">
          Политика сайта «Кабельщик» (интернет-адреса www.cableman.ru) в отношении обработки персональных данных пользователей сети интернет
        </a>
      </v-col>
      <v-col class="col-12 col-md-4">
        <div class="subscriptionBlock d-flex" v-if="!false">
          <a href="https://www.cableman.ru/newsletter/subscriptions" class="white--text">
            <v-icon small class="white--text mr-4">mdi-email</v-icon>
          </a>
          <div class="v-text-body-2">
            <a href="https://www.cableman.ru/newsletter/subscriptions" class="white--text">
              Подпишись на ежедневную рассылку «КАБЕЛЬЩИКА»
            </a>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="v-text-body-2 mt-8 mb-0">
      Все права на материалы и новости, опубликованные на сайте www.cableman.ru, охраняются в соответствии с законодательством РФ. Допускается цитирование без согласования с редакцией не более трети от объема оригинального материала, с обязательной прямой гиперссылкой. ©2005 - 2022 «Кабельщик»
    </div>
  </div>
</template>

<style lang="scss">
.subscriptionBlock {
  background-color: #F54748;
  color: white;
  padding: 12px 16px;
  border-radius: 4px;
}
.footerContainer {  
  background-color: transparent!important;
  color: white;
  padding-left: 0; 
  padding-right: 0;
	@media screen and (max-width: 960px) {
    background-color: #CFD8DC!important; 
    /* background-color: white!important; */
    color: black;
  }
}

.footerContainer a, .footerContainer .footerLink {  
  color: white!important;
	@media screen and (max-width: 960px) {
    color: black!important;
  }
}
.v-text-body-2 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.linkChip {
  display: inline-block;
  background-color: #600DCB;
  border-radius: 12px;
  padding: 2px 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #FFFFFF;
  margin-right: 12px;
  margin-top: 8px;
}
.linkChip a {
  color: #FFFFFF!important;
  text-decoration: none;
}

.subscriptionBlock a {
  text-decoration: none;
}
.subscriptionBlock .v-text-body-2 a:hover {
  text-decoration: underline;
}
</style>
<script>
export default {
  data: () => ({
    
  }),
};
</script>
